import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const ConnectedEnterprise = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="Connected Enterprise"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <h6 className="mt-5">Enable, Modernize and Manage Digital Backbone with seamless integration, API first approach and process modelling</h6>
                        <p className="mt-3">With an increased adoption of digitization across industries and channels, Digital Integration has become a key enabler for unlocking organization’s digital backbone by adopting API first approach through variety of integration technologies tailored to their ecosystem and enabled by industry best practices</p>
                        <p className="mt-1">It is imperative for an organization to establish integration strategy and select the right partner for managing their integration ecosystem by helping them in selecting the right platform from the emerging technologies that are best suited to their needs.</p>

                        <p className="mt-1">TEQT partners with leading industry technology vendors to transform application delivery by adopting modern API development principles, architecture and global standards. We always focuses on delivering business value to customers and for that leverages our deep expertise in Digital Integration combined with industry’s leading platform resulting in business value for customers. Our solutions will help enterprises around the world gain deeper data insights, improve performance, enhance the user experience, and deliver greater speed and eﬃciency to meet changing customer needs</p>

                        <p className="mt-1">TEQT has global partnership with several leading API & Integration product vendors, driving adoption across US, UK, Europe, APAC and India providing Consulting / Advisory services, Development, Support and Maintenance services for application integration solution leveraging cloud native tools.</p>

                        <p className="mt-1">TEQT is committed to invest in building capabilities on API & Integration platform through dedicated CoE, certified practitioners and competence / best practices acquired through multiple engagements</p>

                        <h6>Our differentiated approach:</h6>
                        <p className="mt-2">We foster an API-first approach mindset while engaging with customer who are looking for transforming their landscape and achieve digitization.</p>

                        <ul className="circle-list">
                            <li className="mt-2"> <span className="text-black">Align with Business -</span>Establish the business objectives and goals. Conduct workshops and deep-dive sessions with business stakeholders to establish clear goals and targets</li>
                            <li className="mt-2"><span className="text-black"> Assess & Discovery -</span>Process Understanding and Diagnostic of current states, understand business pain-points and technology challenges.</li>
                            <li className="mt-2"><span className="text-black"> Develop Roadmap – </span>Provide future state recommendations including functional view, future state architecture, Recommend Operating Model and Implementation approach</li>
                            <li className="mt-2"> <span className="text-black">Plan & Prepare -</span> Prepare high-level implementation plan and activities , Set up operating model, governance, tools and implementation team. Setup API Management, baseline standards and practices</li>

                            <li className="mt-2"> <span className="text-black">Execute - </span>Create the project requirements , Set up Agile Squads , Execute in iterations with feedback loops</li>

                            <li className="mt-2"> <span className="text-black">Supported by CoE – </span>All the services are executed and fast accelerated by using solution assets developed by TEQT centre of excellence and complete oversight is given by CoE core group members for successful delivery</li>
                        </ul>
                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query ConnectedEnterprisePageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

ConnectedEnterprise.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default ConnectedEnterprise;
